@tailwind base;
@tailwind components;
@tailwind utilities;

::-webkit-scrollbar {
    width: 6px;
  }
  
  ::-webkit-scrollbar-thumb {
    background-color: #718096;
  }
  
  ::-webkit-scrollbar-track {
    background-color: #CBD5E0;
  }